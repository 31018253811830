<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <h4 id="traffic" class="card-title mb-0">Proyectos - Consumo de horas</h4>
            <div class="small text-muted">Detalle del consumo de horas para el proyecto</div>
          </b-col>
        </b-row>
      </b-card>
      
      <b-row>
        <b-col sm="6">
          <b-card header-tag="header" footer-tag="footer">
            
            <b-badge variant="dark" v-if="this.projectSelected.customer">
              {{this.projectSelected.customer.name}}
            </b-badge>
            {{this.projectSelected.name}}  

            <FullCalendar defaultView="dayGridMonth" 
                          :plugins="calendarPlugins"                           
                          :locale="calendarOptions.locale"
                          :weekends="true" 
                          :events="calendarOptions.event"
                          :selectable="true" 
                          @dateClick="dateClick "/>
          </b-card>                            
        </b-col>
        <b-col sm="6">
          <b-card header-tag="header" footer-tag="footer">
            <b-row class="mb-3">
              <b-col md="6">

                <b-badge variant="dark" v-if="this.projectSelected.customer">
                  {{this.projectSelected.customer.name}}
                </b-badge>
                {{this.projectSelected.name}}  

                <h2>{{moment(this.dateSelected).format('DD')}} de 
                    {{moment(this.dateSelected).format('MMMM')}} de 
                    {{moment(this.dateSelected).format('YYYY')}}</h2>
              </b-col>              
            </b-row>
            <b-row>
              <b-col md="12">                        
                <b-table class="mb-0"
                        responsive="sm"
                        head-variant="dark"
                        :hover="true"
                        :small="true"
                        :fixed="false"
                        :items="table.items"
                        :fields="table.fields"
                        v-if="table.items.length">
                    
                    <template v-slot:cell(employee_id)="data">                      
                      <b>{{ data.item.employee.id }} - {{ data.item.employee.name }}</b>                      
                    </template>
                  
                    <template v-slot:cell(description)="data">
                      <div v-if="data.item.description.length>50" :title="data.item.description">
                        {{ data.item.description.substring(0,50) }} ...
                      </div>
                      <div v-else>
                        {{ data.item.description }}
                      </div>
                    </template>

                    <template v-slot:cell(hours)="data">
                      <b>{{ data.item.hours }} hs</b>
                    </template>

                    <template v-slot:cell(f_action)="data">
                      <b-dropdown right text="" size="sm" variant="outline-dark" class="pull-right" v-if="!data.item.sale_id">
                        <b-dropdown-header v-if="!data.item.sale_id && !data.item.not_invoice">Facturar</b-dropdown-header>        
                        <b-dropdown-item @click="checkForInvoice(data.item, 'check')" v-if="!data.item.sale_id && !data.item.not_invoice && !data.item.check_for_invoice">
                          <b-icon icon="calendar-check"></b-icon> Marcar para facturar
                        </b-dropdown-item>                          
                        <b-dropdown-item @click="checkForInvoice(data.item, 'uncheck')" v-if="!data.item.sale_id && !data.item.not_invoice && data.item.check_for_invoice">
                          <b-icon icon="calendar2-x"></b-icon> Desmarcar para facturar
                        </b-dropdown-item>             
                        <b-dropdown-header v-if="!data.item.sale_id && !data.item.check_for_invoice">Sin Factura</b-dropdown-header>                                             
                        <b-dropdown-item @click="checkNotInvoice(data.item, 'check')" v-if="!data.item.sale_id && !data.item.check_for_invoice && !data.item.not_invoice">
                          <b-icon icon="archive"></b-icon> No facturar
                        </b-dropdown-item>                          
                        <b-dropdown-item @click="checkNotInvoice(data.item, 'uncheck')" v-if="!data.item.sale_id && !data.item.check_for_invoice && data.item.not_invoice">
                          <b-icon icon="recycle"></b-icon> Deshacer
                        </b-dropdown-item>                         
                      </b-dropdown>
                    </template>

                </b-table>
                <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>                
              </b-col>
            </b-row>            
            <b-row class="mt-3" align-h="center" v-if="table.items.length">
              <b-col md="5" center>
                <b-table-simple hover small caption-top responsive>
                  <b-thead head-variant="dark">
                    <b-tr>                      
                      <b-th colspan="2" class="text-center">TOTALES</b-th>
                    </b-tr>
                  </b-thead>      
                  <b-tfoot>
                    <b-tr>
                      <b-td colspan="7" variant="secondary" class="text-right">
                        Total de hs en día: <b>{{this.totalProjects.toFixed(2)}} Hs</b>
                      </b-td>
                    </b-tr>
                  </b-tfoot>                             
                </b-table-simple>
              </b-col>              
            </b-row>
          </b-card>

          <b-card header-tag="header" footer-tag="footer" v-if="forInvoice>0">
            <b-row>
              <b-col md="12">
                <h2>Horas pendientes de facturación</h2>
              </b-col>       
              <b-col md="12">
                <div v-if="projectSelected.monthly_hours_included == 1">
                  <b-alert variant="primary" show>El cliente tiene <b>{{this.projectSelected.monthly_hours_included}} Hora</b> dentro del mes</b-alert>
                </div>
                <div v-if="projectSelected.monthly_hours_included > 1">
                  <b-alert variant="primary" show>El cliente tiene <b>{{this.projectSelected.monthly_hours_included}} Horas</b> dentro del mes</b-alert>
                </div>                
              </b-col>       
            </b-row>       
            <b-row class="mb-3">
              <b-col md="6" style="font-size: 20px;">
                <u>TOTAL:</u> 
                &nbsp;
                <span>
                  <b>{{ this.forInvoice }}</b> Horas 
                </span>
              </b-col>      
              <b-col md="6">           
                <b-button type="button" variant="dark" class="pull-right ml-2" @click="invoiceHours()">
                  <b-icon icon="file-earmark-ruled"></b-icon>
                  Facturar
                </b-button> 
                <b-button type="button" variant="outline-info" class="pull-right" @click="invoiceHoursManual()">                  
                  Marcar como facturado
                </b-button>                                      
              </b-col>        
            </b-row>                        
          </b-card>

          <b-card header-tag="header" footer-tag="footer">
            <b-row>
              <b-col md="12">
                <h2>
                  Resultado {{moment(this.dateSelected).format('MMMM')}} - {{moment(this.dateSelected).format('YYYY')}}
                </h2>                
              </b-col>
              <b-col md="12">
                <b-table-simple hover small caption-top responsive>
                  <b-thead head-variant="dark">
                    <b-tr>                      
                      <b-th class="text-left">ESTADO</b-th>
                      <b-th class="text-center">HORAS</b-th>
                    </b-tr>
                  </b-thead>      
                  <b-tbody>
                    <b-tr>                      
                      <b-td class="text-left" variant="secondary">
                        NO FACTURABLES
                      </b-td>
                      <b-td class="text-center">
                        <div v-html="getDetailHoursMonths()['no_facturables'] + ' / ' + this.projectSelected.monthly_hours_included + ' hs'"></div>                        
                      </b-td>
                    </b-tr>
                    <b-tr>                      
                      <b-td class="text-left" variant="danger">
                        SIN FACTURAR
                      </b-td>
                      <b-td class="text-center">
                        <div v-html="getDetailHoursMonths()['sin_facturar'] + ' hs'"></div>                        
                      </b-td>                                      
                    </b-tr>
                    <b-tr>                      
                      <b-td class="text-left" variant="warning">
                        POR FACTURAR
                      </b-td>
                      <b-td class="text-center">
                        <div v-html="getDetailHoursMonths()['por_facturar'] + ' hs'"></div>                        
                      </b-td>                         
                    </b-tr>
                    <b-tr>                      
                      <b-td class="text-left" variant="success">
                        FACTURADAS
                      </b-td>
                      <b-td class="text-center">
                        <div v-html="getDetailHoursMonths()['facturadas'] + ' hs'"></div>                        
                      </b-td>                   
                    </b-tr>                                            
                  </b-tbody>                  
                </b-table-simple>
              </b-col>              
            </b-row>                                        
          </b-card>                  

        </b-col>
      </b-row>              

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="goProjects()">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>
        </b-row>
      </b-card>         
    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Profiles from '@/config/profiles'
  import Error from '@/handler/error'
  import ErrorToken from '@/handler/errorToken'
  import Session from '@/handler/session'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import moment from 'moment'   

  import FullCalendar from '@fullcalendar/vue'
  import dayGridPlugin from '@fullcalendar/daygrid'    
  import esLocale from '@fullcalendar/core/locales/es';
  import interactionPlugin from '@fullcalendar/interaction';

  export default {
    components: {                
      FullCalendar
    },        
    data: () => {
      return {      
        access: {
          module_id: Modules.PROYECTOS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'detailHoursProjects',
          elements: {}
        }, 
        calendarPlugins: [ dayGridPlugin, interactionPlugin ],        
        calendarOptions: {
          locale: esLocale,
          event: []
        },
        table : {
          items: [],
          fields: [            
            {key: 'employee_id', label: 'Empleado', sortable: true},            
            {key: 'description', label: 'Detalle'},            
            {key: 'hours', label: 'Horas', class:"text-center", sortable: true},
            {key: 'f_action', label:''},
          ],        
        },                 
        dateSelected: moment(),       
        projectSelectedID: 0,
        projectSelected: {},
        forInvoice: 0,      
        arr: {
          detailsHours: []
        }  
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },  
    mounted () {      
      this.projectSelectedID = this.$route.params.projectID  

      moment.locale('es');
      this.load()   
      this.getHoursForInvoice()   
    },      
    computed: {
      totalProjects: function() {
        var total = 0
        this.table.items.forEach(element => {
          if(element.projects_id) {
            total = total + parseFloat(element.hours)
          }          
        }) 
        return total       
      },
      isEmployee(){
        if(Helper.getEmployee()) {
          if(Helper.getEmployee().permission_admin_projects) {
            return false  
          } else {
            return true
          }                    
        } else {
          return false
        }
      },           
    },
    methods: {
      validation() {
        var employee = Helper.getEmployee()        
        if(employee && !employee.permission_admin_projects) {
          if(this.projectSelected.employee_id != employee.id) {
            this.$router.push({ name: 'Error403' })
          }
        }
      },
      dateClick(date) { 
        this.dateSelected = date.dateStr                 
        this.getHours()        
      },
      load(){
        this.loadCalendar()
        this.getHours()
        this.loadProjectSelected()
      },
      loadCalendar() {   
        let loader = this.$loading.show();

        this.calendarOptions.event = []

        var data = {          
          project_id: this.projectSelectedID         
        }
        var result = serviceAPI.obtenerHorasByProject(data)

        result.then((response) => {                    
          var data = response.data
          this.arr.detailsHours = data.projects

          data.projects.forEach(element => {
            var event = {}

            let title = ''
            let color = ''
            
            if(!element.sale_id && !element.check_for_invoice && element.not_invoice) {
              title = 'No Facturable: ' + element.hours + ' hs'
              color = "#ababab"
            }

            if(!element.sale_id && !element.check_for_invoice && !element.not_invoice) {
              title = 'Sin Facturar: ' + element.hours + ' hs'
              color = "#fc6262"
            }

            if(!element.sale_id && element.check_for_invoice && !element.not_invoice) {
              title = 'Por Facturar: ' + element.hours + ' hs'
              color = "#f6b132"
            } 
            
            if(element.sale_id && element.check_for_invoice && !element.not_invoice) {
              title = 'Facturadas: ' + element.hours + ' hs'
              color = "#3bf83b"              
            } 

            event = {
              title: title,
              start: element.date,
              end: element.date,
              color: color,
            }            
            this.calendarOptions.event.push(event)            
          });           

          loader.hide()
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error))
        });                     
      },
      getHours() {
        let loader = this.$loading.show();
        var data = {
          project_id: this.projectSelectedID,
          date: this.dateSelected 
        }              
        var result = serviceAPI.obtenerHorasByDiaByProyecto(data)

        result.then((response) => {                    
          var data = response.data
          
          data.forEach(element => {            
            let variant = ""
            if(!element.sale_id && !element.check_for_invoice && element.not_invoice) {              
              variant = "secondary"
            }

            if(!element.sale_id && !element.check_for_invoice && !element.not_invoice) {              
              variant = "danger"
            }

            if(!element.sale_id && element.check_for_invoice && !element.not_invoice) {              
              variant = "warning"
            } 
            
            if(element.sale_id && element.check_for_invoice && !element.not_invoice) {
              variant = "success"              
            }             

            element._rowVariant = variant
            this.table.items.push(element)
          });    
          
          this.table.items = data   
                              
          loader.hide()
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error))
        });
      }, 
      loadProjectSelected(){
        let loader = this.$loading.show();
        var result = serviceAPI.mostrarProyectos(this.projectSelectedID)

        result.then((response) => {                            
          var data = response.data                            
          this.projectSelected = data        
          
          // VALIDACION DE VISUALIZACION
          this.validation()
          // FIN VALIDACION

          loader.hide()
        }) 
        .catch(error => {       
          loader.hide()   
          this.$awn.alert(ErrorToken.valid(error));
        }) 
      },

      getHoursForInvoice() {        
        var result = serviceAPI.obtenerHoursForInvoice({
          projects_id: this.projectSelectedID
        })

        result.then((response) => {                    
          var data = response.data
          this.forInvoice = parseFloat(data)
        })
      },
      checkForInvoice(item, operation='check') {
        let loader = this.$loading.show();        
        
        var result = serviceAPI.guardarCheckForInvoice({
          id: item.id
        });

        result.then((response) => {          
          loader.hide()

          this.loadCalendar()
          this.getHours()
          this.getHoursForInvoice()
          
          let msj = ''
          if(operation=='check') {
            msj = item.hours + " Horas agregadas para facturar"
          } 
          if(operation=='uncheck') {
            msj = item.hours + " Horas quitadas para facturar"
          } 

          this.$awn.success("Datos guardados con éxito. " + msj);
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },
      invoiceHours() {               
        if(!this.projectSelected.service_invoice) {
          this.$awn.alert("Falta configurar en el proyecto, el servicio vinculado a la facturación.");
          return false
        }

        var list_default = this.projectSelected.customer.price_list.price_list_id       

        var price_default = 0
        this.projectSelected.service_invoice.prices.forEach(element => {
          if(element.price_list_id == list_default) {
            price_default = parseFloat(element.amount_total)
          }
        });

        this.$router.push({ 
          name: 'SalesStaffSalesGenerateByProjects', 
          params: {
            projectsID: this.projectSelected.id
          },
          query: {            
            customers_id: this.projectSelected.customer_id,
            customers: JSON.stringify(this.projectSelected.customer),                        
            detail: JSON.stringify([
              {
                quantity: this.forInvoice, 
                service: this.projectSelected.service_invoice,
                services: this.projectSelected.service_invoice,
                currency: this.projectSelected.customer.price_list.list.currency.code,
                price: price_default,                
                description: this.projectSelected.service_invoice.display_sales_detail,
                price_list: this.projectSelected.customer.price_list.list,                
              }
            ])
          } 
        })
      },
      invoiceHoursManual() {
        this.$bvModal.msgBoxConfirm('¿Se marcarán como facturadas las [' + this.forInvoice + ' horas] del proyecto [' + this.projectSelected.name + ']. ¿Desea continuar?', {
          title: 'Marcar como facturado',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'info',
          okTitle: 'MARCAR',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'info',
          headerTextVariant: 'dark',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {   
            let loader = this.$loading.show();        
            
            var result = serviceAPI.guardarCheckInvoiceManual({
              projects_id: this.projectSelectedID
            });

            result.then(() => {          
              loader.hide()

              this.loadCalendar()
              this.getHours()
              this.getHoursForInvoice()
              
              this.$awn.success("Datos guardados con éxito.");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
      },

      checkNotInvoice(item, operation='check') {
        let loader = this.$loading.show();        
        
        var result = serviceAPI.guardarCheckNotInvoice({
          id: item.id
        });

        result.then((response) => {          
          loader.hide()

          this.loadCalendar()
          this.getHours()
          this.getHoursForInvoice()
          
          let msj = ''
          if(operation=='check') {
            msj = item.hours + " Horas no facturable"
          } 
          if(operation=='uncheck') {
            msj = item.hours + " Horas reestablecidas para facturar"
          } 

          this.$awn.success("Datos guardados con éxito. " + msj);
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },

      getDetailHoursMonths() {
        var arrHours = []
        arrHours["no_facturables"] = 0
        arrHours["sin_facturar"] = 0
        arrHours["por_facturar"] = 0
        arrHours["facturadas"] = 0

        var date_selected = null
        if(moment.isMoment(this.dateSelected)) {
          date_selected = this.dateSelected
        } else {
          date_selected = moment(this.dateSelected)
        }

        if(this.arr.detailsHours) {          
          this.arr.detailsHours.forEach(element => {                        
            if(moment(element.date).format('Y-M') == date_selected.format('Y-M')) {
              if(element.not_invoice) {
                arrHours["no_facturables"] += parseFloat(element.hours)
              }              
            
              if(!element.check_for_invoice && !element.not_invoice) {
                arrHours["sin_facturar"] += parseFloat(element.hours)
              }
            
              if(element.check_for_invoice && !element.not_invoice && !element.sale_id) {
                arrHours["por_facturar"] += parseFloat(element.hours)
              }
            
              if(element.sale_id) {
                arrHours["facturadas"] += parseFloat(element.hours)
              }
            }                                                          
          });
        }

        return arrHours
      },

      goProjects() {
        this.$router.push({ name: 'ProjectStaffCrudProject' })
      },    
    }
  }
</script>
<style>
  .project-detail-hours-badge {
    font-size: 13px;
  }
</style>